import axios from 'axios';
import instance from './base';

export async function createArchives(start, end) {
  await instance.post('/v1/user_records/archives', {
    start_from: start,
    end_at: end,
  });
}

export async function getArchives() {
  const rsp = await instance.get('/v1/user_records/archives', {

  });
  return rsp.data;
}

const downloadInstance = axios.create({
  baseURL: '/api',
  withCredentials: true,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
  },
});
export async function getMonthAppointments(startAt, endAt) {
  const rsp = await downloadInstance.get(`/v1/appointments/export?start_at=${startAt}&end_at=${endAt}`);
  return rsp.data;
}
