<template lang="pug">
  .record-download-page.flex.align-start.column.gap-20
    .desc 選擇年份和月份，將下載選定範圍內的預約列表
    .flex.align-center.gap-12
      el-date-picker(format="yyyy-MM", v-model="month", type="month", placeholder="選擇年份和月份")
      el-button(type="primary", @click="downloadFile") 下載檔案
  </template>

<script>
import {
  getMonthAppointments,
} from '@/api/archive';

export default {
  data() {
    return {
      month: undefined,
    };
  },
  methods: {
    downloadFile() {
      const start = this.month;
      start.setMonth(start.getMonth(), 1);
      start.setHours(0, 0, 0, 0);
      const end = new Date(start);
      end.setMonth(start.getMonth() + 1, 1);
      this.$execWithLoading(async () => {
        const data = await getMonthAppointments(this.$dateToTimestamp(start), this.$dateToTimestamp(end));
        const csv = this.arrayToCsv(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `appointments-${this.month.getFullYear()}-${this.month.getMonth() + 1}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      }, (e) => {
        this.$showAxiosException('下載檔案失敗', e);
      });
    },
    arrayToCsv(datas) {
      const header = ['id', 'price', 'start', 'end', 'note', 'location_type', 'appointment_type', 'payment_method', 'accounting_model_id', 'location_id', 'patient_id', 'professional_id', 'project_id', 'appointment_subtype', 'user_location', 'fh_id', 'accounting_name', 'accounting_type', 'accounting_value', 'is_hourly_fee', 'project_name', 'project_organization_id', 'organization_name', 'patient_name', 'patient_gender', 'patient_birthday', 'patient_refer_source', 'professional_name', 'branch', 'space_name', 'tags', 'main_requirement_tags', 'newebpay_trx_id'];
      const rows = datas.map((d) => {
        const row = [];
        header.forEach((h) => {
          row.push(`"${(d[h] || '').toString().replace(/"/g, '""')}"`);
        });
        return row;
      });
      return [header, ...rows].map((r) => r.join(',')).join('\n');
    },
  },
};
</script>

  <style lang="scss" scoped>

  </style>
