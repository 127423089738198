<template lang="pug">
.view-part.flex
  .view-table.customScrollbar(ref="table")
    .header.flex.column.align-start(:style="widthStyle")
      .tr.flex.align-stretch(:style="widthStyle")
        .th.time &nbsp;
        .th.room.flex.align-center(
          v-for="room in rooms", :key="room.id")
          span {{room.name}}
    .body.flex.column.align-start(:style="widthStyle")
      template(v-for="hour in hours")
        .tr.flex.align-stretch(
          :key="`up-${hour.value}`", :data-hour="hour.value", :style="widthStyle")
          .td.time.flex.align-center {{ hour.text }}
          .td.room(v-for="room in rooms", :key="room.id")
        .tr.flex.align-stretch(:key="`down-${hour.value}`", :style="widthStyle")
          .td.time
          .td.room(v-for="room in rooms", :key="room.id")
    template(v-for="activity in activities", v-if="rooms.length > 0")
      activity-block(
        :key="activity.id"
        v-if="activity.status !== 'cancelled' && activity.location_type === 'inhouse'"
        :editable="activity.end_at > editBoundary"
        :rooms="rooms", :tags="tags", :activity="activity")
    template(v-for="appointment in reserveList", v-if="rooms.length > 0")
      appointment-block(
        :key="appointment.id"
        v-if="appointment.status !== 'cancelled'"
        @click="showDetail(appointment)"
        :editable="appointment.end_at > editBoundary"
        :rooms="rooms", :tags="tags", :appointment="appointment")
  el-dialog(
    width="600px"
    title="預約詳細記錄"
    :visible.sync="detailVisible"
  )
    appointment-detail(
      @edit="execCommand('edit', $event)"
      @recreate="execCommand('recreate', $event)"
      @cancel="execCommand('cancel', $event)"
      :tags="tags"
      :data="detailAppointment"
      :orgMap="projectOrgMap")
  appointment-edit(
    ref="appointmentEdit"
    @finish="reloadDataWithDelay"
  )
  appointment-creator(
    ref="appointmentCreator"
    @finish="reloadDataWithDelay"
  )
</template>

<script>
import { getTags } from '@/api/tags';
import { getBranches } from '@/api/place';
import { getAllAppointments, cancelAppointment, getAppointment } from '@/api/reserve';
import { getActivities } from '@/api/activity';
import { getOrganizations } from '@/api/project';
import AppointmentEditor from '@/components/drawers/AppointmentEdit.vue';
import AppointmentCreate from '@/components/drawers/AppointmentCreate.vue';
import AppointmentBlock from './AppointmentBlock.vue';
import ActivityBlock from './ActivityBlock.vue';
import AppointmentDetail from './AppointmentDetail.vue';

export default {
  name: 'appointment-room-view',
  components: {
    'activity-block': ActivityBlock,
    'appointment-block': AppointmentBlock,
    'appointment-detail': AppointmentDetail,
    'appointment-edit': AppointmentEditor,
    'appointment-creator': AppointmentCreate,
  },
  props: {
    branch: {
      type: Number,
      required: true,
    },
    time: {
      type: Date,
      required: true,
    },
    public: {
      type: Boolean,
      default: false,
    },
    hour: {
      type: Number,
      default: 9,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const hours = [];
    for (let i = 0; i < 24; i += 1) {
      hours.push({
        text: `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`,
        value: i,
      });
    }
    return {
      reserveList: [],
      activities: [],
      tags: [],
      branchMap: {},

      filter: {},

      detailUser: undefined,
      hours,

      detailVisible: false,
      detailAppointment: undefined,
      editBoundary: parseInt((new Date()).getTime() / 1000, 10) - 86400,
      projectOrgMap: {},
    };
  },
  computed: {
    rooms() {
      return this.branchMap[this.branch]?.rooms || [];
    },
    tableWidth() {
      return 160 * this.rooms.length;
    },
    widthStyle() {
      return {
        width: `${this.tableWidth}px`,
      };
    },
  },
  watch: {
    branch() {
      this.changeBranch();
    },
    time() {
      this.changeDate();
    },
  },
  methods: {
    reloadDataWithDelay() {
      setTimeout(() => {
        this.loadData();
      }, 100);
    },
    execCommand(cmd, data) {
      if (cmd === 'edit') {
        this.detailVisible = false;
        this.$refs.appointmentEdit.$emit('show', data);
      } else if (cmd === 'cancel') {
        this.detailVisible = false;
        this.cancelAppointment(data);
      } else if (cmd === 'recreate') {
        this.detailVisible = false;
        this.$refs.appointmentCreator.$emit('show', data);
      }
    },
    async cancelAppointment(appointment) {
      this.$confirm('確定要取消此預約嗎').then(
        () => this.$execWithLoading(async () => {
          await cancelAppointment(appointment.id);
          this.$showSuccess('已取消預約');
          this.loadData();
        }),
      ).catch(() => {});
    },
    getFilter() {
      const start = new Date(this.time);
      start.setHours(0, 0, 0, 0);

      const end = new Date(start);
      end.setHours(23, 59, 59, 999);
      const filter = {
        start_at: parseInt(start.getTime() / 1000, 10),
        end_at: parseInt(end.getTime() / 1000, 10),
        branch: (this.branch !== '' && this.branch) ? this.branch : undefined,
      };
      return filter;
    },
    changeDate() {
      this.page = 1;
      this.$execWithLoading(async () => {
        await this.loadReserveList();
        await this.loadActivities();
      });
    },
    loadData() {
      this.editBoundary = parseInt((new Date()).getTime() / 1000, 10) - 86400;

      this.reserveList = [];
      this.activities = [];
      this.$execWithLoading(async () => {
        await Promise.all([
          this.loadBranch(),
          this.loadReserveList(),
          this.loadTags(),
          this.loadActivities(),
          this.loadOrganizations(),
        ]);

        this.scrollToHour(this.hour);
      });
    },
    async loadOrganizations() {
      const data = await getOrganizations();
      const projectOrgMap = {};
      data.forEach((d) => {
        d.projects.forEach((p) => {
          projectOrgMap[p.id] = d;
        });
      });
      this.projectOrgMap = projectOrgMap;
    },
    async loadActivities() {
      const activities = await getActivities(this.getFilter());
      this.activities = JSON.parse(JSON.stringify(activities));
    },
    async loadReserveList() {
      const reserveList = await getAllAppointments(this.getFilter());
      this.reserveList = reserveList.map((r) => ({
        ...r,
        type: r.appointment_type || r.type || '',
        subtype: r.appointment_subtype || r.subtype || 'statutoryagents',
      }));
    },
    async loadTags() {
      const tags = await getTags();
      this.tags = tags;
    },
    async loadBranch() {
      console.log(this.public);
      const branches = await getBranches(this.public || undefined);
      const branchMap = {};
      branches.forEach((b) => {
        branchMap[b.id] = b;
      });
      this.branchMap = branchMap;
      return branches[0];
    },
    changeBranch() {
      this.loadData();
    },
    scrollToHour(hour) {
      this.$refs.table.scrollTop = 60 * 2 * hour;
    },
    showDetail(current) {
      if (!this.clickable) {
        return;
      }
      this.$execWithLoading(async () => {
        this.detailAppointment = await getAppointment(current.id);
        this.detailVisible = true;
      }, (e) => {
        this.$showAxiosException('載入資料失敗', e);
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadData();
    }, 10);
    this.$on('reload', () => {
      this.loadData();
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.view-part {
  flex: 1;
  background: white;
  margin: 30px;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  .view-table {
    flex: 1;
    border: 1px solid $border-base-color;
    overflow: auto;
    overflow: scroll;
    position: relative;
  }
  .tr {
    flex: 0 60px;
  }
  .header {
    position: sticky;
    top: 0;
    .tr {
      background: #E6F4F4;
      .th {
        background: #E6F4F4;
        border-right: 1px solid $border-base-color;
        &:first-child {
          position: sticky;
          left: 0;
        }
      }
    }
    z-index: 2;
  }
  .body {
    .tr {
      .td {
        border-right: 1px solid $border-base-color;
        border-top: 1px solid $border-info-color;
        background: white;
        &:first-child {
          position: sticky;
          left: 0;
          z-index: 1;
        }
      }
      &:nth-child(2n) {
        .td {
          border-top: 1px solid $border-base-color;
        }
        .td:first-child {
          border-top: none;
        }
      }
    }
  }
  .time {
    flex: 0 0 80px;
    padding: 0 16px;
  }
  .room {
    flex: 0 0 160px;
    padding: 0 16px;
  }
}
</style>
